<template>
  <div class="contracts content-padding">
    <TableOverview
      kind="contract"
      :page-title="labels.contract_overview"
      :searchable="true"
      :settings="settings"
      :footer-buttons="footerButtons"
      :get-page="getContractsMt"
    />
    <router-view
      name="create_contract"
      modal-id="create-contract"
      kind="contract"
      :route-back="{ name: 'contracts' }"
      :title="labels.create_new_contract"
      :title-placeholder="labels.title"
      :text-placeholder="labels.text"
      :required-fields="['title', 'text']"
      :required-data-set="['title', 'text']"
      @entity-created="contractCreated"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import TableOverview from "@/views/Dashboard/Common/TableOverview";
const colors = require("/colors.config.json");
export default {
  name: "Overview",
  components: {
    TableOverview,
  },
  data() {
    return {};
  },
  computed: {
    footerButtons() {
      return [
        {
          label: this.labels.create_new,
          variant: "btn-purple",
          action: () => {
            if (this.$route.name !== "create-contract") {
              this.$router.push({
                name: "create-contract",
                query: { page: this.$route.query.page },
              });
            }
          },
        },
      ];
    },
    settings() {
      return {
        header: [
          {
            headerLabel: this.labels.name,
            headerColor: `${colors.gray_text}`,
            key: "title",
            computeFn: (input) => input,
            bodyColor: `${colors.dark}`,
            columnWidth: "1 1 30%",
            whiteSpace: "nowrap",
          },
          {
            headerLabel: this.labels.group,
            headerColor: `${colors.gray_text}`,
            key: "status",
            computeFn: (input) => input,
            bodyColor: `${colors.gray_text_2}`,
            columnWidth: "1 1 50%",
            textAlign: "center",
          },
        ],
        minTableWidth: "300px",
        actionsWidth: "1 1 20%",
        actionsHeaderLabel: "",
        actions: [
          {
            label: this.labels.open,
            variant: "btn-purple",
            styles: {
              padding: "8px 15px",
              fontWeight: "700",
              selfAlign: "left",
            },
            action: (data) =>
              this.$router.push({
                name: "contract-content",
                params: { id: data._id },
              }),
          },
        ],
      };
    },
    ...mapGetters("helper", ["labels"]),
  },
  methods: {
    ...mapMutations("helper", {
      setTablePagination: "setTablePagination",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("contract", {
      getContracts: "getList",
    }),
    contractCreated() {
      this.getContractsMt(this.$route.query.page);
    },
    getContractsMt(page) {
      let data = { page };
      if (!this.$route.query.page) data.page = 1;
      else if (!page) data.page = this.$route.query.page;

      this.getContracts(data)
        .then((res) => {
          if (res.success) {
            console.log(res);
            this.setTablePagination(res.data.pages);
            if (
              Number(this.$route.query.page) !== Number(res.data.pages.current)
            ) {
              this.$router.push({ query: { page: res.data.pages.current } });
            }
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getContractsMt();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .table-card {
    &__thead {
      font-family: "Oswald", sans-serif;
      text-transform: uppercase;
      font-weight: 400;
      line-height: 55px;
      color: $light_text;
    }

    &-row {
      &__item {
        word-break: break-word;
      }
    }
  }
}
</style>
